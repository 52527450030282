<!-- PrivacyPolicyPage.vue | @/pages/PrivacyPolicyPage.vue -->
<script setup>
import { useHead } from "@vueuse/head";

useHead({
  title: "Privacy Policy | Two Bros Inferencing",
  meta: [
    {
      name: "description",
      content:
        "We respect your privacy and are committed to protecting it through our compliance with our privacy policy.",
    },
    {
      property: "og:description",
      content:
        "We respect your privacy and are committed to protecting it through our compliance with our privacy policy.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
});
</script>

<template>
  <div class="container mx-auto px-4 py-12">
    <h1
      class="text-4xl lg:text-6xl font-bold mt-24 mb-12 text-base-content text-center"
    >
      PRIVACY
    </h1>
    <div class="max-w-lg mx-auto">
      <p class="text-lg mb-6 text-base-content">
        Welcome to twobrosinferencing.com. We respect your privacy and are
        committed to protecting it through our compliance with this policy.
      </p>

      <h2 class="text-lg text-base-content font-semibold">
        1. Data Collection and Use:
      </h2>
      <p class="text-lg mb-6 text-base-content">
        We use Google Analytics to collect data about your interactions with our
        website. This data helps us understand how visitors use our site and
        enables us to improve your experience. We also store user account
        information in a secure database, which includes details provided during
        account creation and subsequent interactions with our site.
      </p>
      <h2 class="max-w-lg mx-auto text-lg text-base-content text-left">
        2. Non-Disclosure of Information:
      </h2>
      <p class="text-lg mb-6 max-w-lg mx-auto text-base-content text-left">
        We do not share your personal information with any third parties, except
        as necessary for the operation of our website, such as with software
        vendors like Google Analytics who assist us in analyzing how our website
        is used. These parties are only permitted to access or use this data to
        perform services on our behalf and are obligated to keep your
        information confidential.
      </p>
      <h2 class="max-w-lg mx-auto text-lg text-base-content text-left">
        3. Use of Cookies:
      </h2>
      <p class="text-lg mb-6 max-w-lg mx-auto text-base-content text-left">
        Our website uses cookies primarily to enhance and personalize your user
        experience. These cookies help us manage site preferences and track user
        interactions, allowing us to tailor our website to better suit your
        needs. We do not use cookies for tracking purposes beyond our website.
      </p>
      <h2 class="max-w-lg mx-auto text-lg text-base-content text-left">
        4. Commitment to Privacy:
      </h2>
      <p class="text-lg mb-6 max-w-lg mx-auto text-base-content text-left">
        We are dedicated to upholding a high standard of privacy and adhere to
        principles that reflect this commitment. We take reasonable measures to
        protect the confidentiality and security of your personal information
        and to ensure that your data is treated securely and in accordance with
        this Privacy Policy.
      </p>
      <h2 class="max-w-lg mx-auto text-lg text-base-content text-left">
        5. Changes to Our Privacy Policy:
      </h2>
      <p class="text-lg mb-12 max-w-lg mx-auto text-base-content text-left">
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. We
        encourage you to review this Privacy Policy periodically for any
        changes.
      </p>
      <div class="text-center mt-12">
        <router-link
          to="/"
          class="bg-primary border-primary border-2 text-base-content px-6 py-2 rounded-full hover:bg-primary-focus hover:border-primary-focus custom-button inline-block"
        >
          I understand
        </router-link>
      </div>
    </div>
  </div>
</template>
