<script setup>
import { useHead } from "@vueuse/head";

useHead({
  title:
    "About | Two Bros Inferencing | Learn more about our platform and services",
  meta: [
    {
      name: "description",
      content:
        "Two Bros Inferencing is a platform that provides easy to use APIs for LLM powered apps. Our platform is designed to be simple and intuitive, allowing you to focus on building your product.",
    },
    {
      property: "og:description",
      content:
        "Two Bros Inferencing is a platform that provides easy to use APIs for LLM powered apps. Our platform is designed to be simple and intuitive, allowing you to focus on building your product.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
});
</script>

<template>
  <div
    class="relative container mx-auto max-w-screen-xl text-center min-h-screen"
  >
    <h1
      class="text-4xl lg:text-6xl font-bold max-w-2xl mx-auto mt-20 md:mt-42 mb-12 text-base-content text-center"
    >
      ABOUT
    </h1>
    <p class="text-lg mb-6 max-w-lg mx-auto text-base-content text-left">
      At Two Bros Inferencing, our mission is to empower developers by
      streamlining the integration of Large Language Models (LLMs) into
      applications. We understand the complexities and challenges that come with
      harnessing the power of LLMs, and we’re here to make the process as
      seamless as possible.
    </p>
    <p class="text-lg mb-6 max-w-lg mx-auto text-base-content text-left">
      We’ve built an opinionated platform that adds a robust layer of structure
      atop raw LLM endpoints. This ensures that you’re not just getting raw
      data, but optimized and structured responses that enhance your
      application’s performance.
    </p>
    <h3 class="text-lg mb-6 max-w-lg mx-auto text-base-content text-left"></h3>
    <p class="text-lg mb-6 max-w-lg mx-auto text-base-content text-left">
      Our platform intelligently routes RAG (Retrieval Augmented Generation)
      augmented user queries to the most cost-effective inference provider that
      meets our stringent quality standards, such as speed and reliability. This
      means you get the best value without compromising on performance.
    </p>
    <h3 class="text-lg mb-6 max-w-lg mx-auto text-base-content text-left"></h3>
    <p class="text-lg mb-6 max-w-lg mx-auto text-base-content text-left">
      We offer basic quality checks on all responses, tailored to the method you
      choose in your API call. This ensures consistency and reliability in the
      outputs your application delivers to end-users.
    </p>
    <p class="text-lg mb-6 max-w-lg mx-auto text-base-content text-left"></p>
    <router-link
      to="/faq"
      class="w-full px-6 py-3 text-base-content rounded bg-neutral-focus hover:bg-base-100 transition duration-300"
      >FAQs</router-link
    >
    <div class="emptySpace mt-0 lg:mt-32"></div>
  </div>
</template>

<style scoped>
.body-link {
  @apply text-secondary-focus hover:text-secondary-focus;
}
</style>
