<template>
  <div class="flex flex-col h-full pb-6">
    <div v-if="!sessionStore.isLoggedIn" class="text-center p-8 flex-grow">
      <h3 class="text-lg font-semibold text-base-content mb-4">
        Please Log In
      </h3>
      <p class="text-base-content opacity-70">
        You need to be logged in to use the chat feature.
      </p>
      <router-link to="/login" class="btn btn-primary mt-4">Log In</router-link>
    </div>

    <div v-else class="flex flex-col h-full">
      <!-- Chat content area - scrollable -->
      <div class="flex-grow overflow-x-hidden overflow-y-auto px-4 pb-10">
        <div
          v-if="!hasPromptBeenSent && !isLoadingThread"
          class="text-center mb-8 pt-8"
        >
          <img
            src="/images/two_bros_inferencing_logo_small.png"
            alt="Logo"
            class="w-16 h-16 mx-auto mb-4"
          />
          <h3 class="text-lg font-semibold text-base-content mb-2">
            2Bros Inferencing
          </h3>
          <p class="text-base-content opacity-70 mb-6">
            Send a message or choose an agent below to get started
          </p>
        </div>

        <div v-if="conversation.length > 0" class="space-y-6">
          <transition-group name="message">
            <div
              v-for="(message, index) in conversation"
              :key="index"
              class="flex"
              :class="message.type === 'user' ? 'justify-end' : 'justify-start'"
            >
              <div
                class="max-w-[80%] px-4 py-0 rounded-lg"
                :class="
                  message.type === 'user'
                    ? 'bg-primary text-primary-content rounded-br-none'
                    : 'bg-base-200 text-base-content rounded-bl-none'
                "
              >
                <div class="flex items-center py-2 gap-3">
                  <img
                    v-if="message.type === 'ai'"
                    src="/images/two_bros_inferencing_logo_small.png"
                    alt="AI"
                    class="w-6 h-6 flex-shrink-0"
                  />
                  <span class="whitespace-pre-wrap text-sm sm:text-base">{{
                    message.content
                  }}</span>
                  <div v-if="message.isLoading" class="typing-indicator ml-1">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </div>

        <div
          v-if="!hasPromptBeenSent && !isLoadingThread"
          class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 mb-24"
        >
          <ActionCard
            v-for="action in actions"
            :key="action.title"
            :action="action"
            @click="sendInitialPrompt(action.title)"
            class="cursor-pointer hover:shadow-lg transition-all duration-300 hover:-translate-y-1"
          />
        </div>
      </div>

      <!-- Fixed input area at bottom -->
      <div
        class="sticky bottom-0 left-0 right-0 border-t border-base-300 px-4 pt-3 bg-base-300 mt-auto"
      >
        <PromptInput
          ref="promptInputRef"
          @prompt-sent="onPromptSent"
          class="max-w-4xl mx-auto"
          :disabled="isWaitingForResponse"
        />
        <div class="text-xs text-center mt-2 text-base-content opacity-70">
          Press ⌘/Ctrl + K to quickly focus the input
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useSessionStore } from "@/stores/session";
import ActionCard from "@/components/chat/ActionCard.vue";
import PromptInput from "@/components/chat/PromptInput.vue";
import {
  sendMessage,
  pollJobStatus,
  startThread,
  getThread,
} from "@/utilities/api/api.js";
import { toast } from "vue3-toastify";

const POLLING_INTERVAL = 1000;
const sessionStore = useSessionStore();
const router = useRouter();

const actions = ref([
  { icon: "🧑‍🏫", title: "General Inference", color: "text-purple-400" },
  { icon: "✍️", title: "Writer Agent", color: "text-blue-400" },
  { icon: "💬", title: "Social Media Agent", color: "text-yellow-400" },
  { icon: "🤖", title: "Celebrity Impression Agent", color: "text-green-400" },
]);

const hasPromptBeenSent = ref(false);
const isLoadingThread = ref(false);
const promptInputRef = ref(null);
const conversation = ref([]);
const isWaitingForResponse = ref(false);
const threadId = ref(null);
const systemMessage = ref("You are a helpful AI assistant.");
let currentPollingInterval = null;

// Handle loading thread data from URL
const loadThreadFromUrl = async () => {
  const queryThreadId = window.location.search.match(/thread=([^&]*)/)?.[1];
  if (!queryThreadId) return;

  try {
    isLoadingThread.value = true;
    const threadResponse = await getThread(queryThreadId);

    threadId.value = threadResponse.threadId;
    systemMessage.value = threadResponse.systemPrompt;
    const { userMessages, responseMessages } = threadResponse.chatHistory;

    // Clear existing conversation
    conversation.value = [];

    // Interleave user and response messages
    let i = 0;
    let j = 0;
    while (i < userMessages.length || j < responseMessages.length) {
      if (i < userMessages.length) {
        addToConversation("user", userMessages[i].content);
        i++;
      }
      if (j < responseMessages.length) {
        addToConversation("ai", responseMessages[j].content);
        j++;
      }
    }

    hasPromptBeenSent.value = conversation.value.length > 0;
  } catch (error) {
    console.error("Error loading thread:", error);
    toast.error(error.message || "Error loading thread", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } finally {
    isLoadingThread.value = false;
  }
};

const onPromptSent = async (prompt) => {
  try {
    if (!sessionStore.isLoggedIn) {
      toast.error("Please log in to use the chat feature", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      router.push("/login");
      return;
    }

    hasPromptBeenSent.value = true;
    isWaitingForResponse.value = true;
    addToConversation("user", prompt);
    const loadingMessageIndex = conversation.value.length;
    addToConversation("ai", "Thinking...", true);

    if (!threadId.value) {
      const threadResponse = await startThread(systemMessage.value);
      threadId.value = threadResponse.threadId;

      // Update URL without triggering navigation
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?thread=${threadId.value}`
      );
    }

    const response = await sendMessage({
      threadId: threadId.value,
      userMessage: prompt,
      systemMessage: systemMessage.value,
    });

    startPolling(response.requestId, loadingMessageIndex);
  } catch (error) {
    console.error("Error:", error);
    updateAIMessage(
      conversation.value.length - 1,
      error.message || "An error occurred"
    );
    isWaitingForResponse.value = false;
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
};

const sendInitialPrompt = async (actionTitle) => {
  switch (actionTitle) {
    case "General Inference":
      systemMessage.value = "You are a helpful AI assistant.";
      break;
    case "Writer Agent":
      systemMessage.value =
        "You are an expert writer who helps with content creation.";
      break;
    case "Social Media Agent":
      systemMessage.value =
        "You are a social media expert who helps create engaging content.";
      break;
    case "Celebrity Impression Agent":
      systemMessage.value =
        "You are an expert at mimicking celebrity speaking styles and mannerisms.";
      break;
  }

  if (promptInputRef.value) {
    promptInputRef.value.setAndSubmitPrompt(actionTitle);
  }
};

const startPolling = async (requestId, messageIndex) => {
  clearInterval(currentPollingInterval);

  currentPollingInterval = setInterval(async () => {
    try {
      const status = await pollJobStatus(requestId);

      if (status.status === "finished" && status.job) {
        clearInterval(currentPollingInterval);
        updateAIMessage(messageIndex, status.job.response);
        isWaitingForResponse.value = false;
      } else if (status.status === "not found") {
        clearInterval(currentPollingInterval);
        updateAIMessage(messageIndex, "Sorry, the response was not found.");
        isWaitingForResponse.value = false;
      }
    } catch (error) {
      console.error("Error polling status:", error);
      clearInterval(currentPollingInterval);
      updateAIMessage(
        messageIndex,
        "Sorry, there was an error getting the response."
      );
      isWaitingForResponse.value = false;
    }
  }, POLLING_INTERVAL);
};

const updateAIMessage = (index, content, isLoading = false) => {
  if (index >= 0 && index < conversation.value.length) {
    conversation.value[index] = {
      ...conversation.value[index],
      content,
      isLoading,
    };
  }
};

const addToConversation = (type, content, isLoading = false) => {
  conversation.value = [...conversation.value, { type, content, isLoading }];
  scrollToBottom();
};

const scrollToBottom = () => {
  nextTick(() => {
    const conversationElement = document.querySelector(".overflow-y-auto");
    if (conversationElement) {
      conversationElement.scrollTop = conversationElement.scrollHeight;
    }
  });
};

const handleKeyDown = (event) => {
  if ((event.metaKey || event.ctrlKey) && event.key === "k") {
    event.preventDefault();
    const promptInput = document.getElementById("prompt-input");
    if (promptInput) {
      promptInput.focus();
      // Add a visual indication that the input is focused
      promptInput.classList.add("ring-2", "ring-primary");
      setTimeout(() => {
        promptInput.classList.remove("ring-2", "ring-primary");
      }, 500);
    }
  }
};

// Handle browser back/forward navigation
window.addEventListener("popstate", () => {
  loadThreadFromUrl();
});

onMounted(() => {
  window.addEventListener("keydown", handleKeyDown);
  // Load initial state if there's a thread ID
  loadThreadFromUrl();
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyDown);
  clearInterval(currentPollingInterval);
});
</script>

<style scoped>
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: theme("colors.base-300") theme("colors.base-100");
  scroll-behavior: smooth;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 8px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: theme("colors.base-100");
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: theme("colors.base-300");
  border-radius: 4px;
}

/* Message animations */
.message-enter-active,
.message-leave-active {
  transition: all 0.3s ease;
}

.message-enter-from,
.message-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

/* Typing indicator */
.typing-indicator {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.typing-indicator span {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: currentColor;
  opacity: 0.6;
}

.typing-indicator span:nth-child(1) {
  animation: typing 1s infinite 0.2s;
}

.typing-indicator span:nth-child(2) {
  animation: typing 1s infinite 0.4s;
}

.typing-indicator span:nth-child(3) {
  animation: typing 1s infinite 0.6s;
}

@keyframes typing {
  0%,
  100% {
    transform: translateY(0);
    opacity: 0.6;
  }
  50% {
    transform: translateY(-5px);
    opacity: 1;
  }
}

@keyframes loading-spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
