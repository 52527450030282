<template>
  <div class="min-h-[80vh] flex flex-col">
    <div v-if="!sessionStore.isLoggedIn" class="text-center p-8">
      <h3 class="text-lg font-semibold text-base-content mb-4">
        Please Log In
      </h3>
      <p class="text-base-content opacity-70">
        You need to be logged in to use the chat feature.
      </p>
      <router-link to="/login" class="btn btn-primary mt-4">Log In</router-link>
    </div>

    <div v-else class="flex-grow flex flex-col">
      <div class="flex-grow overflow-y-auto px-4 mb-4">
        <!-- If no image has been uploaded, show the upload box -->
        <div
          v-if="!uploadedImage && fontResults.length === 0"
          class="text-center mb-8"
        >
          <img
            src="/images/two_bros_inferencing_logo_small.png"
            alt="Logo"
            class="w-16 h-16 mx-auto mb-4"
          />
          <h3 class="text-lg font-semibold text-base-content mb-2">
            2Bros Inferencing
          </h3>
          <p class="text-base-content opacity-70 mb-6">
            Upload an image of a font to get started. We'll use it to find the
            most similar free fonts available
          </p>
          <!-- Stylized image upload box -->
          <div
            class="border-2 border-dashed border-primary p-4 rounded-md text-center cursor-pointer hover:bg-base-100 hover:border-secondary w-full max-w-125 mx-auto mt-14"
            @click="triggerFileInput"
            @dragover.prevent
            @drop.prevent="handleDrop"
          >
            <input
              type="file"
              accept="image/*"
              class="hidden"
              ref="fileInput"
              @change="handleImageUpload"
            />
            <p class="text-sm text-gray-500 mt-3">
              Drag & Drop or click to upload an image
            </p>
          </div>
        </div>

        <!-- Preview of uploaded image with spinner while processing -->
        <div
          v-if="uploadedImage && fontResults.length === 0"
          class="text-center mb-8"
        >
          <img
            :src="uploadedImage"
            alt="Uploaded image preview"
            class="mx-auto mb-4 max-w-xs"
          />
          <div class="flex flex-col items-center">
            <div class="loading mb-2"></div>
            <p class="text-base-content">Processing image...</p>
          </div>
        </div>

        <!-- Font Finder Results -->
        <div v-if="fontResults.length > 0" class="mt-8">
          <div
            v-for="(result, index) in fontResults"
            :key="index"
            class="mb-4 flex items-center"
          >
            <img
              :src="getFontImageUrl(result.image)"
              alt="Font preview"
              class="w-16 h-16 mr-4"
            />
            <div>
              <div class="font-bold">{{ result.font }}</div>
              <a
                :href="result.link"
                target="_blank"
                class="text-blue-500 flex items-center"
              >
                <i class="fas fa-external-link-alt mr-1"></i>
                Visit Font
              </a>
            </div>
          </div>
          <!-- Start Over Button -->
          <button class="btn btn-secondary mt-4" @click="resetPage">
            Start Over
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useSessionStore } from "@/stores/session";
import {
  pollJobStatus,
  sendFontFinderJob,
  getThread,
} from "@/utilities/api/api.js";
import { toast } from "vue3-toastify";

const sessionStore = useSessionStore();
const route = useRoute();

const hasPromptBeenSent = ref(false);
const isLoadingThread = ref(false);
const conversation = ref([]);
const threadId = ref(null);
const systemMessage = ref("You are a helpful AI assistant.");
const fontResults = ref([]); // Finished font finder results
const uploadedImage = ref(null); // Local preview URL of the uploaded image

let currentPollingInterval = null;

const loadThreadFromQuery = async () => {
  try {
    const queryThreadId = route.query.thread;
    console.log("Loading thread:", queryThreadId);
    if (queryThreadId) {
      isLoadingThread.value = true;
      const threadResponse = await getThread(queryThreadId);
      console.log("Thread response:", threadResponse);
      threadId.value = threadResponse.threadId;
      systemMessage.value = threadResponse.systemPrompt;
      // const { userMessages, responseMessages } = threadResponse.chatHistory;
      // (Interleaving messages if needed)
      hasPromptBeenSent.value = true;
    }
  } catch (error) {
    console.error("Error loading thread:", error);
    toast.error(error.message || "Error loading thread", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } finally {
    isLoadingThread.value = false;
  }
};

watch(
  () => route.query,
  () => {
    loadThreadFromQuery();
  },
  { immediate: true }
);

const handleDrop = async (event) => {
  const files = event.dataTransfer.files;
  if (files && files.length > 0) {
    await handleImageUpload({ target: { files: [files[0]] } });
  }
};

const fileInput = ref(null);
const triggerFileInput = () => {
  if (fileInput.value) {
    fileInput.value.click();
  }
};

const handleImageUpload = async (event) => {
  const file = event.target.files[0];
  if (file) {
    console.log("Uploaded image file:", file);
    // Set the preview URL so the uploaded image is shown
    uploadedImage.value = URL.createObjectURL(file);
    try {
      // Send the font finder job to the backend
      const response = await sendFontFinderJob({
        image: file,
        threadId: threadId.value,
      });
      console.log("Font Finder Job response:", response);
      const requestId = response.requestId;
      // Start polling for the finished job (spinner remains visible)
      startPolling(requestId);
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error(error.message || "An error occurred", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
};

const startPolling = async (requestId) => {
  clearInterval(currentPollingInterval);
  currentPollingInterval = setInterval(async () => {
    try {
      const status = await pollJobStatus(requestId, "ff");
      if (status.status === "finished" && status.job) {
        clearInterval(currentPollingInterval);
        // Once finished, populate fontResults so the spinner and processing text are hidden
        fontResults.value = status.job.results;
      } else if (status.status === "not found") {
        clearInterval(currentPollingInterval);
        toast.error("Sorry, the response was not found.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error polling status:", error);
      clearInterval(currentPollingInterval);
      toast.error("Sorry, there was an error getting the response.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, 1000);
};

const getFontImageUrl = (imagePath) => {
  const fileName = imagePath.split("/").pop();
  return `/font-finder?image=${fileName}`;
};

const resetPage = () => {
  hasPromptBeenSent.value = false;
  isLoadingThread.value = false;
  conversation.value = [];
  fontResults.value = [];
  threadId.value = null;
  systemMessage.value = "You are a helpful AI assistant.";
  uploadedImage.value = null;
};
</script>

<style scoped>
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: theme("colors.base-300") theme("colors.base-100");
}
.overflow-y-auto::-webkit-scrollbar {
  width: 8px;
}
.overflow-y-auto::-webkit-scrollbar-track {
  background: theme("colors.base-100");
}
.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: theme("colors.base-300");
  border-radius: 4px;
}
.loading {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: 3px solid currentColor;
  border-radius: 50%;
  border-right-color: transparent;
  animation: loading-spin 0.75s linear infinite;
}
@keyframes loading-spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
