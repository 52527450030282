// api.js
import { useSessionStore } from "@/stores/session";
// const API_BASE_URL = "https://api.twobrosinferencing.com";
let API_BASE_URL = "https://api.twobrosinferencing.com";
if (process.env.VUE_APP_ENVIRONMENT === "development") {
  API_BASE_URL = "http://localhost:8080";
}

const handleApiResponse = async (response) => {
  if (!response.ok) {
    throw new Error(`API error: ${response.status}`);
  }
  return response.json();
};

export const startThread = async (systemMessage) => {
  const sessionStore = useSessionStore();

  const response = await fetch(`${API_BASE_URL}/start-thread`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": sessionStore.user.apiKey,
    },
    body: JSON.stringify({
      systemPrompt: systemMessage,
    }),
  });
  return handleApiResponse(response);
};

export const getThread = async (threadId) => {
  const sessionStore = useSessionStore();

  const response = await fetch(
    `${API_BASE_URL}/get-thread?threadId=${threadId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": sessionStore.user.apiKey,
      },
    }
  );
  return handleApiResponse(response);
};

export const sendMessage = async (messageData, type = "llm") => {
  const sessionStore = useSessionStore();

  if (!sessionStore.isLoggedIn || !sessionStore.user?.apiKey) {
    throw new Error("Please log in to use the chat feature");
  }

  const response = await fetch(`${API_BASE_URL}/new-job?type=${type}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-API-KEY": sessionStore.user.apiKey,
    },
    body: JSON.stringify({
      threadId: messageData.threadId,
      userMessage: messageData.userMessage,
      systemMessage: messageData.systemMessage,
      assistantMessage: "",
    }),
  });

  return handleApiResponse(response);
};

export const pollJobStatus = async (requestId, type = "llm") => {
  const sessionStore = useSessionStore();

  if (!sessionStore.isLoggedIn || !sessionStore.user?.apiKey) {
    throw new Error("Please log in to use the chat feature");
  }

  const response = await fetch(
    `${API_BASE_URL}/status?requestId=${requestId}&type=${type}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": sessionStore.user.apiKey,
      },
    }
  );

  return handleApiResponse(response);
};

export const sendFontFinderJob = async (jobData) => {
  const sessionStore = useSessionStore();

  if (!sessionStore.isLoggedIn || !sessionStore.user?.apiKey) {
    throw new Error("Please log in to use the feature");
  }

  // Create a FormData object and append the image and any additional fields
  const formData = new FormData();
  formData.append("image", jobData.image); // jobData.image is a File object
  if (jobData.threadId) {
    formData.append("threadId", jobData.threadId);
  }
  // Indicate this is a font finder job
  formData.append("type", "ff");

  // Use fetch without setting the Content-Type header manually.
  const response = await fetch(`${API_BASE_URL}/font-finder?type=ff`, {
    method: "POST",
    headers: {
      "X-API-KEY": sessionStore.user.apiKey,
    },
    body: formData,
  });

  return handleApiResponse(response);
};
