<template>
  <div class="min-h-[70vh] flex flex-col">
    <!-- User must be logged in -->
    <div v-if="!sessionStore.isLoggedIn" class="text-center p-8">
      <h3 class="text-lg font-semibold text-base-content mb-4">
        Please Log In
      </h3>
      <p class="text-base-content opacity-70">
        You need to be logged in to use the rotator feature.
      </p>
      <router-link to="/login" class="btn btn-primary mt-4">Log In</router-link>
    </div>

    <div v-else class="flex-grow flex flex-col">
      <!-- Main display area -->
      <div class="flex-grow overflow-y-auto px-4 mb-4">
        <!-- If a prompt has been sent, show the prompt and processing status/output -->
        <div v-if="hasPromptBeenSent" class="flex flex-col items-center">
          <div class="mb-4">
            <p class="text-lg font-semibold">{{ userPrompt }}</p>
          </div>
          <div v-if="isWaitingForResponse" class="flex flex-col items-center">
            <div class="loading mb-2"></div>
            <p class="text-base-content">processing request...</p>
          </div>
          <div
            v-else-if="videoUrl"
            class="flex flex-col items-center w-full max-w-4xl"
          >
            <!-- Video display -->
            <video
              :src="videoUrl"
              controls
              autoplay
              loop
              class="w-full rounded-lg mb-6"
            ></video>

            <!-- Code display -->
            <div v-if="codeUrl" class="w-full mt-4">
              <div class="flex justify-between items-center mb-2">
                <h3 class="text-lg font-semibold">Generated Manim Code</h3>
                <div class="flex gap-2">
                  <button
                    @click="copyCodeToClipboard"
                    class="btn btn-sm btn-outline"
                    :class="{ 'btn-success': codeCopied }"
                  >
                    {{ codeCopied ? "Copied!" : "Copy Code" }}
                  </button>
                  <a
                    :href="codeUrl"
                    target="_blank"
                    class="btn btn-sm btn-outline"
                    download="animation.py"
                  >
                    Download
                  </a>
                </div>
              </div>
              <pre
                v-if="codeContent"
                class="bg-base-200 p-4 rounded-lg overflow-x-auto text-sm"
              >
                <code v-text="codeContent"></code>
              </pre>
              <div v-else class="flex justify-center">
                <div class="loading"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- If no prompt has been sent, show the example ActionCards -->
        <div v-else>
          <div class="text-center mb-8">
            <img
              src="/images/two_bros_inferencing_logo_small.png"
              alt="Logo"
              class="w-16 h-16 mx-auto mb-4"
            />
            <h3 class="text-lg font-semibold text-base-content mb-2">
              2Bros Inferencing
            </h3>
            <p class="text-base-content opacity-70 mb-6">
              Enter a shape rotation visualization prompt below to get started
            </p>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
            <ActionCard
              v-for="action in actions"
              :key="action.title"
              :action="action"
              @click="sendInitialPrompt(action.title)"
            />
          </div>
        </div>
      </div>

      <!-- Show the prompt input field only if no prompt has been submitted yet -->
      <div v-if="!hasPromptBeenSent" class="px-4">
        <PromptInput
          ref="promptInputRef"
          @prompt-sent="onPromptSent"
          class="max-w-4xl mx-auto"
          :disabled="isWaitingForResponse"
        />
        <div class="text-xs text-center mt-2 text-base-content opacity-70">
          Press ⌘/Ctrl + K to quickly focus the input
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useSessionStore } from "@/stores/session";
import ActionCard from "@/components/chat/ActionCard.vue";
import PromptInput from "@/components/chat/PromptInput.vue";
import {
  sendMessage,
  pollJobStatus,
  startThread,
  getThread,
} from "@/utilities/api/api.js";
import { toast } from "vue3-toastify";
let API_BASE_URL = "https://api.twobrosinferencing.com";
const POLLING_INTERVAL = 1000;
const sessionStore = useSessionStore();
const router = useRouter();
const route = useRoute();

const actions = ref([
  { icon: "🔵", title: "Circle to Square", color: "text-purple-400" },
  { icon: "🔴", title: "Circle to Triangle", color: "text-blue-400" },
  { icon: "🟢", title: "Sphere to Cube", color: "text-yellow-400" },
  { icon: "🟧", title: "Cube to Torus", color: "text-green-400" },
]);

const hasPromptBeenSent = ref(false);
const isWaitingForResponse = ref(false);
const promptInputRef = ref(null);
const threadId = ref(null);
const systemMessage = ref("You are a helpful AI assistant.");
const userPrompt = ref("");
const videoUrl = ref("");
const codeUrl = ref("");
const codeContent = ref("");
const codeCopied = ref(false);
let currentPollingInterval = null;

// Copy code to clipboard
const copyCodeToClipboard = async () => {
  if (codeContent.value) {
    try {
      await navigator.clipboard.writeText(codeContent.value);
      codeCopied.value = true;
      setTimeout(() => {
        codeCopied.value = false;
      }, 2000);
    } catch (err) {
      toast.error("Failed to copy code", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
};

// Fetch code content via simple API proxy
// Fetch code content via API proxy with authentication
const fetchCodeContent = async (url) => {
  try {
    // Extract the task ID from the URL
    // Expected format: https://twobrosbucket.sfo3.digitaloceanspaces.com/videos/{taskID}/code.py
    const urlParts = url.split("/");
    const taskIDIndex = urlParts.indexOf("videos") + 1;

    if (taskIDIndex < urlParts.length) {
      const taskID = urlParts[taskIDIndex];

      console.log("Extracted taskID:", taskID);
      console.log(
        "Fetching from:",
        `${API_BASE_URL}/get-code-content?taskID=${taskID}`
      );

      // Use the API endpoint with the taskID parameter and include the API key
      const response = await fetch(
        `${API_BASE_URL}/get-code-content?taskID=${taskID}`,
        {
          headers: {
            "X-API-KEY": sessionStore.user.apiKey,
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Server response:", errorText);
        throw new Error(
          `Failed to fetch code: ${response.status} - ${response.statusText}`
        );
      }

      // Debug: log response headers and first part of the response
      console.log(
        "Response headers:",
        Object.fromEntries([...response.headers.entries()])
      );
      const responseText = await response.text();
      console.log("Response text preview:", responseText.substring(0, 100));

      // Try to parse the response as JSON
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (parseError) {
        console.error("JSON parse error:", parseError);
        // If it's not JSON but seems to be code, use it directly
        if (
          responseText.includes("from manim import") ||
          responseText.includes("class ")
        ) {
          codeContent.value = responseText;
          return;
        } else {
          throw parseError;
        }
      }

      codeContent.value = data.code || "// Code content not available";
    } else {
      throw new Error("Could not extract taskID from URL");
    }
  } catch (error) {
    console.error("Error fetching code:", error);

    // As a fallback, try to fetch the code directly if CORS isn't blocking it
    try {
      console.log("Attempting direct fetch as fallback...");
      const directResponse = await fetch(codeUrl.value);
      if (directResponse.ok) {
        const directText = await directResponse.text();
        if (
          directText.includes("from manim import") ||
          directText.includes("class ")
        ) {
          console.log("Direct fetch succeeded!");
          codeContent.value = directText;
          return;
        }
      }
    } catch (directError) {
      console.error("Direct fetch fallback failed:", directError);
    }

    codeContent.value = `// Unable to load code directly.
// Please use the download button to access the code.`;
  }
};

// Handle loading thread data from URL
const loadThreadFromUrl = async () => {
  const queryThreadId = route.query.thread;
  if (!queryThreadId) {
    hasPromptBeenSent.value = false;
    isWaitingForResponse.value = false;
    threadId.value = null;
    userPrompt.value = "";
    videoUrl.value = "";
    codeUrl.value = "";
    codeContent.value = "";
    return;
  }

  try {
    const threadResponse = await getThread(queryThreadId);
    if (threadResponse.chatHistory.userMessages?.length > 0) {
      threadId.value = threadResponse.threadId;
      userPrompt.value = threadResponse.chatHistory.userMessages[0].content;
      hasPromptBeenSent.value = true;
      isWaitingForResponse.value =
        !threadResponse.chatHistory.responseMessages?.length;

      if (threadResponse.chatHistory.responseMessages?.length > 0) {
        const responseMessage = threadResponse.chatHistory.responseMessages[0];

        // Check if it has the new format with video and code
        if (responseMessage.video && responseMessage.code) {
          videoUrl.value = responseMessage.video;
          codeUrl.value = responseMessage.code;
          // Fetch the code content
          await fetchCodeContent(codeUrl.value);
        } else {
          // Fallback to old format
          videoUrl.value = responseMessage.content;
        }
      }
    }
  } catch (error) {
    toast.error("Error loading thread", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
};

const onPromptSent = async (prompt) => {
  if (!sessionStore.isLoggedIn) {
    toast.error("Please log in to use the rotator feature", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    router.push("/login");
    return;
  }

  // Set UI state first
  userPrompt.value = prompt;
  hasPromptBeenSent.value = true;
  isWaitingForResponse.value = true;
  videoUrl.value = "";
  codeUrl.value = "";
  codeContent.value = "";

  try {
    if (!threadId.value) {
      const threadResponse = await startThread(systemMessage.value);
      threadId.value = threadResponse.threadId;

      // Update URL silently without triggering a navigation
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?thread=${threadId.value}`
      );
    }

    const response = await sendMessage(
      {
        threadId: threadId.value,
        userMessage: prompt,
        systemMessage: systemMessage.value,
      },
      "rotator"
    );
    startPolling(response.requestId);
  } catch (error) {
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    isWaitingForResponse.value = false;
  }
};

const sendInitialPrompt = (actionTitle) => {
  onPromptSent(actionTitle);
};

const startPolling = async (requestId) => {
  clearInterval(currentPollingInterval);
  currentPollingInterval = setInterval(async () => {
    try {
      const status = await pollJobStatus(requestId, "rotator");
      if (status.status === "finished" && status.job) {
        clearInterval(currentPollingInterval);

        // Check for the new job format that includes video and code URLs
        if (status.job.video && status.job.code) {
          videoUrl.value = status.job.video;
          codeUrl.value = status.job.code;
          // Fetch the code content
          await fetchCodeContent(codeUrl.value);
        } else {
          // Fallback to the old format
          videoUrl.value = status.job.url;
        }

        isWaitingForResponse.value = false;
      } else if (status.status === "not found") {
        clearInterval(currentPollingInterval);
        toast.error("Sorry, the response was not found.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        isWaitingForResponse.value = false;
      }
    } catch (error) {
      clearInterval(currentPollingInterval);
      toast.error("Error polling job status", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      isWaitingForResponse.value = false;
    }
  }, POLLING_INTERVAL);
};

const handleKeyDown = (event) => {
  if ((event.metaKey || event.ctrlKey) && event.key === "k") {
    event.preventDefault();
    document.getElementById("prompt-input")?.focus();
  }
};

// Watch for direct URL changes (browser back/forward)
window.addEventListener("popstate", () => {
  loadThreadFromUrl();
});

onMounted(() => {
  window.addEventListener("keydown", handleKeyDown);
  // Load initial state if there's a thread ID in URL
  loadThreadFromUrl();
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyDown);
  clearInterval(currentPollingInterval);
});
</script>

<style scoped>
.min-h-\[70vh\] {
  min-height: 70vh;
}

.loading {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: 4px solid currentColor;
  border-radius: 50%;
  border-right-color: transparent;
  animation: loading-spin 0.75s linear infinite;
}

@keyframes loading-spin {
  to {
    transform: rotate(360deg);
  }
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: monospace;
}
</style>
