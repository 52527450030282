<script setup>
import AppBar from "@/components/navigation/AppBar";
import FooterBar from "@/components/navigation/FooterBar";
import SideNavigation from "@/components/navigation/SideNavigation";
import { useSessionStore } from "@/stores/session";
import { ref, onMounted, onBeforeUnmount, watch, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { SpeedInsights } from "@vercel/speed-insights/vue";
import axios from "axios";

const session = useSessionStore();
const isOpen = ref(window.innerWidth > 768);
const isMobile = ref(window.innerWidth <= 768);
const isLoading = ref(false);
const router = useRouter();
const route = useRoute();
const isLoggedIn = computed(() => session.isLoggedIn);
const isSideNavExpanded = computed(() => !session.isSideNavCollapsed);

// New computed property to determine if the current route is the homepage
const isHomePage = computed(() => route.name === "Home");

// New computed property to check if current route is the chat page
const isChatPage = computed(() => {
  return (
    route.path === "/dashboard/chat" ||
    route.fullPath.includes("/dashboard/chat")
  );
});

// New computed property to determine the gradient class
const gradientClass = computed(() => {
  if (isHomePage.value) {
    return session.theme === "cedrosdark" ? "dark-gradient" : "light-gradient";
  }
  return "";
});

const toggleNav = () => {
  isOpen.value = !isOpen.value;
};

const handleResize = () => {
  isMobile.value = window.innerWidth <= 768;
  isOpen.value = !isMobile.value;
  if (!isMobile.value && isLoggedIn.value) {
    session.setSideNavCollapsed(true); // Ensure it's collapsed on resize
  }
};

router.beforeEach((to, from, next) => {
  isLoading.value = true;
  next();
});

router.afterEach(() => {
  isLoading.value = false;
});

// Theme handling
const initializeTheme = () => {
  const savedTheme = localStorage.getItem("theme") || "cedrosdark";
  session.setTheme(savedTheme);
  applyTheme(savedTheme);
};

const applyTheme = (theme) => {
  document.documentElement.setAttribute("data-theme", theme);
  document.documentElement.classList.toggle("dark", theme === "cedrosdark");
};

watch(
  () => session.theme,
  (newTheme) => {
    localStorage.setItem("theme", newTheme);
    applyTheme(newTheme);
  }
);

// User authentication fetch
const fetchUser = async () => {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_BACKEND_API_URL}/auth/user`,
      { withCredentials: true }
    );
    session.setUser(response.data);
    if (response.data.theme) {
      session.setTheme(response.data.theme);
    }
    session.setSideNavCollapsed(true); // Ensure it's collapsed when user logs in
  } catch (error) {
    // console.error("Error fetching user:", error);
    session.clearUser();
  }
};

onMounted(() => {
  window.addEventListener("resize", handleResize);
  initializeTheme();
  fetchUser();
  session.setSideNavCollapsed(true); // Ensure it's collapsed on initial load
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});
</script>

<template>
  <SpeedInsights />
  <div class="flex flex-col min-h-screen" :data-theme="session.theme">
    <!-- AppBar -->
    <app-bar class="block"></app-bar>

    <!-- Main content with conditional SideNavigation -->
    <div class="flex flex-grow relative site-background" :class="gradientClass">
      <SideNavigation v-if="isLoggedIn && !isMobile" />
      <main
        class="flex-grow relative overflow-x-hidden"
        :class="{
          'ml-16': isLoggedIn && !isMobile && !isSideNavExpanded,
          'ml-64': isLoggedIn && !isMobile && isSideNavExpanded,
        }"
      >
        <button
          class="btn btn-square btn-ghost p-4 fixed top-4 left-4 z-10"
          @click="toggleNav"
          v-if="isMobile"
        >
          <font-awesome-icon icon="bars" />
        </button>

        <div
          v-if="isLoading"
          class="flex justify-center items-center h-screen w-full"
        >
          <span class="loading loading-spinner loading-lg"></span>
        </div>
        <router-view v-else></router-view>
      </main>
    </div>

    <footer-bar v-if="!isChatPage"></footer-bar>
  </div>
</template>

<style scoped>
.site-background {
  @apply bg-base-300;
}

/* Dark Theme Gradient */
.dark-gradient {
  background: rgba(0, 0, 0, 1);
  background-image: radial-gradient(
    circle farthest-side at 50% -96%,
    rgba(255, 119, 0, 0.24),
    #cee6f5 0%,
    rgba(125, 129, 168, 0.66) 0%,
    rgba(255, 255, 255, 0)
  );
}

/* Light Theme Gradient */
.light-gradient {
  background: rgba(255, 255, 255, 1);
  background-image: radial-gradient(
    circle farthest-side at 50% -96%,
    rgba(255, 199, 100, 0.24),
    #ffefc3 0%,
    rgba(245, 245, 245, 0.66) 0%,
    rgba(0, 0, 0, 0)
  );
}
.main-content {
  flex-grow: 1;
  transition: margin-left 0.3s ease;
}

.router-view-content {
  padding: 20px 0;
}

@media (min-width: 1200px) {
  .router-view-content {
    padding: 40px 0;
  }
}
</style>
