<!-- TermsOfServicePage.vue | @/pages/TermsOfServicePage.vue -->
<script setup>
import { useHead } from "@vueuse/head";

useHead({
  title: "Terms of Service | Two Bros Inferencing",
  meta: [
    {
      name: "description",
      content:
        "Review the Terms of Service for Two Bros Inferencing. Understand the terms and conditions that govern your use of our platform.",
    },
    {
      property: "og:description",
      content:
        "Review the Terms of Service for Two Bros Inferencing. Understand the terms and conditions that govern your use of our platform.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
});
</script>

<template>
  <div class="container mx-auto px-4 py-12">
    <h1
      class="text-4xl lg:text-6xl font-bold mt-24 mb-12 text-base-content text-center"
    >
      TERMS OF SERVICE
    </h1>
    <div class="max-w-lg mx-auto">
      <p class="text-lg mb-6 text-base-content">
        Welcome to twobrosinferencing.com. By accessing or using our services,
        you agree to be bound by these Terms of Service. Please read them
        carefully.
      </p>

      <h2 class="text-lg text-base-content font-semibold">
        1. Use of Services:
      </h2>
      <p class="text-lg mb-6 text-base-content">
        Our services are provided "as is" without warranties of any kind, either
        express or implied. We do not guarantee the accuracy, completeness, or
        usefulness of any information provided. Your use of the services is at
        your sole risk.
      </p>

      <h2 class="text-lg text-base-content font-semibold">
        2. Limitation of Liability:
      </h2>
      <p class="text-lg mb-6 text-base-content">
        Two Bros Inferencing shall not be liable for any indirect, incidental,
        special, consequential, or punitive damages arising out of or related to
        your use of the services. This includes, but is not limited to, damages
        for loss of profits, goodwill, use, data, or other intangible losses.
      </p>

      <h2 class="text-lg text-base-content font-semibold">
        3. User Responsibilities:
      </h2>
      <p class="text-lg mb-6 text-base-content">
        You are responsible for ensuring that your use of the services complies
        with all applicable laws and regulations. You agree not to misuse the
        services or help anyone else to do so.
      </p>

      <h2 class="text-lg text-base-content font-semibold">
        4. Intellectual Property:
      </h2>
      <p class="text-lg mb-6 text-base-content">
        All content and materials available on our website are the property of
        Two Bros Inferencing and are protected by intellectual property laws.
        Unauthorized use of any content is strictly prohibited.
      </p>

      <h2 class="text-lg text-base-content font-semibold">
        5. Changes to Services:
      </h2>
      <p class="text-lg mb-6 text-base-content">
        We reserve the right to modify or discontinue the services at any time
        without notice. We are not liable to you or any third party for any
        modification, price change, suspension, or discontinuance of the
        services.
      </p>

      <h2 class="text-lg text-base-content font-semibold">6. Termination:</h2>
      <p class="text-lg mb-6 text-base-content">
        We may suspend or terminate your access to the services at our sole
        discretion, without prior notice or liability, for any reason
        whatsoever, including but not limited to a breach of these Terms.
      </p>

      <h2 class="text-lg text-base-content font-semibold">7. Governing Law:</h2>
      <p class="text-lg mb-12 text-base-content">
        These Terms of Service are governed by and construed in accordance with
        the laws of the jurisdiction in which Two Bros Inferencing operates,
        without regard to its conflict of law provisions.
      </p>

      <div class="text-center">
        <router-link
          to="/"
          class="bg-primary border-primary border-2 text-base-content px-6 py-2 rounded-full hover:bg-primary-focus hover:border-primary-focus inline-block"
        >
          I Agree
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Additional styling if needed */
</style>
