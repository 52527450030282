<!-- DocsPage.vue -->
<script setup>
import { ref, watch } from "vue";
import { useHead } from "@vueuse/head";

useHead({
  title: "Documentation | Two Bros Inferencing",
  meta: [
    {
      name: "description",
      content:
        "Access the documentation for Two Bros Inferencing to learn how to integrate our platform into your applications.",
    },
    {
      property: "og:description",
      content:
        "Access the documentation for Two Bros Inferencing to learn how to integrate our platform into your applications.",
    },
    {
      property: "og:image",
      content:
        "https://twobrosinferencing.com/images/two_bros_inferencing_logo_vertical.jpg",
    },
  ],
});

const sections = [
  { title: "GETTING STARTED", content: "Content for Getting Started..." },
  { title: "METHODS", content: "Content for API Reference..." },
  { title: "ERRORS", content: "Content for Platform Structure..." },
  { title: "PROVIDERS", content: "Content for Providers..." },
  { title: "SUPPORT", content: "Content for Support..." },
];

const activeSection = ref(sections[0]);

/**
 * For the mobile dropdown, we track which section is selected by title.
 * This is initialized to the activeSection's title.
 */
const selectedSectionTitle = ref(activeSection.value.title);

/**
 * When the dropdown changes, find the matching section and set it as active.
 */
function updateActiveSection() {
  const newSection = sections.find(
    (s) => s.title === selectedSectionTitle.value
  );
  if (newSection) {
    activeSection.value = newSection;
  }
}

/**
 * Keep the dropdown's selected value in sync if activeSection changes
 * from the desktop sidebar.
 */
watch(activeSection, (newVal) => {
  selectedSectionTitle.value = newVal.title;
});
</script>

<template>
  <!-- Container for the entire page -->
  <div class="relative min-h-screen">
    <!-- MOBILE DROPDOWN (visible only on small screens) -->
    <div class="md:hidden p-4 border-b-1 border-base-100 w-full mt-20">
      <label for="sectionSelect" class="sr-only">Select a Section</label>
      <select
        id="sectionSelect"
        class="select select-bordered w-full"
        v-model="selectedSectionTitle"
        @change="updateActiveSection"
      >
        <option
          v-for="section in sections"
          :key="section.title"
          :value="section.title"
        >
          {{ section.title }}
        </option>
      </select>
    </div>

    <!-- DESKTOP SIDEBAR -->
    <aside
      class="hidden md:block fixed top-30 left-0 h-[calc(100vh-4rem)] w-56 p-6 border-r border-gray-200 overflow-y-auto"
      style="z-index: 10"
    >
      <h2 class="text-xl font-bold mb-6">SECTIONS</h2>
      <nav>
        <ul>
          <li
            v-for="section in sections"
            :key="section.title"
            @click="activeSection = section"
            class="mb-4 cursor-pointer"
          >
            <a
              :class="[
                'text-lg',
                activeSection.title === section.title
                  ? 'text-accent font-semibold text-sm'
                  : 'text-base-content text-sm',
              ]"
            >
              {{ section.title }}
            </a>
          </li>
        </ul>
      </nav>
    </aside>

    <!-- MAIN CONTENT AREA -->
    <!-- 
      - md:ml-64 => leaves space for the desktop sidebar
      - mt-24 => adjust to push content below any nav bar
    -->
    <main class="md:ml-64 p-6 md:p-10 mt-6 md:mt-24">
      <h1 class="text-3xl font-bold mb-6">{{ activeSection.title }}</h1>
      <p class="text-base-content">{{ activeSection.content }}</p>
    </main>
  </div>
</template>

<style scoped>
/* Adjust as needed, or remove if not using the fade transition anymore */
</style>
